.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;

  &.particles {
    background-color: #0e0e0e;
    transition: background-color 0.5s;
  }
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}